/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_tree .
 *= require_self
 */

@import url('https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i');

@import "~bootstrap/scss/bootstrap";

@import "d3_elements";
// @import "scaffolds";
@import "scrolling-nav";

@import "~dropzone/dist/dropzone";

body {
  font-family: "Nunito", "Helvetica Neue";
  font-size: 14px;
  // line-height: 18px;

  // background: url('../images/aircraft-1.jpg') no-repeat center center fixed;
  background: url('../images/atterra-3.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;

}



html, body {
  height: 100%;
  margin: 0;
}
.wrapper {
  min-height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -96px;
}
.footer,
.push {
  height: 96px;
}



header.transparentus {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-photo-12 {
  // background-image: '/assets/ross-parmly-rf6ywHVkrlY-unsplash.jpg';
  // background: url('https://source.unsplash.com/scUBcasSvbE/1920x1080') no-repeat center center fixed;
  background: url('../images/aircraft-1.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

footer {

}
section#main {
  background-color: rgba(255, 255, 255, 0.6);
}

#error_explanation {
  padding-left: 10px;
  border-left: 5px solid red;
  color: red;
  h2 {
    font-size: 1.3em;
  }
}


.modal-full {
  min-width: 100%;
  margin: 10px;

  .modal-content {
      min-height: 100vh;
  }
}

table.result-table {
  .waiting {
    color: #b7c2c9;
  }
  .landed {
    color: #1c7600;
  }
  .holding {
    color: #9e0c0a;
  }
}

.verticale {
  // transform: rotate(270deg);
  writing-mode: tb-rl;
  transform: rotate(-180deg);

}



table.table-dark {
  a {
    color: #cce3ff;
    &:hover {
      color: #8cc8ff;

    }
  }

}
